/*
 * Copyright 2019 the original author or authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import * as $ from 'jquery';
import BootstrapUiControlEvent from './bootstrap-ui-control-event';
/**
 * Base implementation for a {@link BootstrapUiControlAdapter} that fires {@link BootstrapUiControlEvent}s on the target element.
 */
var BaseControlAdapter = /** @class */ (function () {
    function BaseControlAdapter(target) {
        this.target = target;
    }
    BaseControlAdapter.prototype.triggerChange = function () {
        $(this.getTarget()).trigger(BootstrapUiControlEvent.CHANGE, [this]);
    };
    BaseControlAdapter.prototype.triggerSubmit = function () {
        $(this.getTarget()).trigger(BootstrapUiControlEvent.SUBMIT, [this]);
    };
    BaseControlAdapter.prototype.getTarget = function () {
        return this.target;
    };
    return BaseControlAdapter;
}());
export default BaseControlAdapter;
