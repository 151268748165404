var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Copyright 2019 the original author or authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @author Steven Gentens
 * @since 2.2.0
 */
import BaseControlAdapter from '../support/base-control-adapter';
import { createControlValueHolder } from '../support/bootstrap-ui-control-value-holder';
/**
 * {@link BootstrapUiControlAdapter} for basic html elements.
 * This adapter sets and retrieves the value using jquery's val method.
 * The target of the control adapter is the node on which it is registered.
 *
 * @see initializeDateTimePickers
 */
var BasicControlAdapter = /** @class */ (function (_super) {
    __extends(BasicControlAdapter, _super);
    function BasicControlAdapter(target) {
        var _this = _super.call(this, target) || this;
        _this.initialValue = $(_this.getTarget()).val();
        $(target).on('change', function (event) { return _this.triggerChange(); });
        return _this;
        // TODO configure 'bootstrapui.submit' event
        // prevent opening the element on enter, but see it as 'submitting' the value instead.
        // $( target ).keypress( this, ( event ) => {
        //     if ( event.key === 'Enter' ) {
        //         this.triggerSubmit();
        //     }
        // } );
    }
    BasicControlAdapter.prototype.getValue = function () {
        var value = $(this.getTarget()).val();
        return [createControlValueHolder(value, value, this.getTarget())];
    };
    BasicControlAdapter.prototype.reset = function () {
        this.selectValue(this.initialValue);
    };
    BasicControlAdapter.prototype.selectValue = function (newValue) {
        $(this.getTarget()).val(newValue);
    };
    return BasicControlAdapter;
}(BaseControlAdapter));
export default BasicControlAdapter;
/**
 * Initializes a {@link BasicControlAdapter} for a given node.
 *
 * @param node to initialize
 */
export function createBasicControlAdapter(node) {
    return new BasicControlAdapter(node);
}
