var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Copyright 2019 the original author or authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @author Steven Gentens
 * @since 2.2.0
 */
import SelectControlAdapter from './select-control-adapter';
/**
 * {@link BootstrapUiControlAdapter} for <a href="https://developer.snapappointments.com/bootstrap-select/">bootstrap-select</a> elements.
 * The target of the control adapter is the node on which it is registered.
 */
var BootstrapSelectControlAdapter = /** @class */ (function (_super) {
    __extends(BootstrapSelectControlAdapter, _super);
    function BootstrapSelectControlAdapter(target) {
        return _super.call(this, target) || this;
    }
    BootstrapSelectControlAdapter.prototype.initializeEventTriggers = function () {
        var _this = this;
        $(this.getTarget()).on('changed.bs.select', function (event) { return _this.triggerChange(); });
        // TODO configure 'bootstrapui.submit' event
        // prevent opening the element on enter, but see it as 'submitting' the value instead.
        // $( this.getTarget() ).closest( '.bootstrap-select' )
        //     .find( 'button' )
        //     .keypress( this, ( event ) => {
        //         if ( event.key === 'Enter' ) {
        //             // event.preventDefault();
        //             this.triggerSubmit();
        //         }
        //     } );
    };
    BootstrapSelectControlAdapter.prototype.selectValue = function (newValue) {
        $(this.getTarget()).selectpicker('val', newValue);
    };
    return BootstrapSelectControlAdapter;
}(SelectControlAdapter));
export default BootstrapSelectControlAdapter;
/**
 * Initializes a {@link BootstrapSelectControlAdapter} for a given node.
 *
 * @param node to initialize
 */
export function createBootstrapSelectControlAdapter(node) {
    return new BootstrapSelectControlAdapter(node);
}
