var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Copyright 2019 the original author or authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @author Steven Gentens
 * @since 2.2.0
 */
import BaseControlAdapter from '../support/base-control-adapter';
import { createControlValueHolder } from '../support/bootstrap-ui-control-value-holder';
/**
 * {@link BootstrapUiControlAdapter} for <a href="https://github.com/Eonasdan/bootstrap-datetimepicker">Eonasdan Bootstrap datepicker</a> elements.
 * The target of the control adapter is the node on which it is registered.
 *
 * @see initializeDateTimePickers
 */
var DatePickerControlAdapter = /** @class */ (function (_super) {
    __extends(DatePickerControlAdapter, _super);
    function DatePickerControlAdapter(target, exportFormat) {
        var _this = _super.call(this, target) || this;
        _this.exportFormat = exportFormat;
        _this.initialValue = _this.getDateTimePicker().date();
        _this.valueHolder = $('input[type=hidden]', target)[0];
        $(target).on('change.datetimepicker', function (event) { return _this.triggerChange(); });
        return _this;
        // TODO configure 'bootstrapui.submit' event
        // $( target ).find( 'input[type="text"]' ).keyup( this, ( event ) => {
        //     if ( event.key === 'Enter' ) {
        //         // event.preventDefault();
        //         this.triggerSubmit();
        //     }
        // } );
    }
    DatePickerControlAdapter.prototype.getValue = function () {
        var date = this.getDateTimePicker().date();
        var formattedValue = date ? date.format(this.exportFormat) : null;
        var value = $(this.valueHolder).val();
        return [createControlValueHolder(formattedValue, value, this.getTarget())];
    };
    DatePickerControlAdapter.prototype.reset = function () {
        this.selectValue(this.initialValue);
    };
    DatePickerControlAdapter.prototype.selectValue = function (newValue) {
        this.getDateTimePicker().date(newValue);
    };
    DatePickerControlAdapter.prototype.getDateTimePicker = function () {
        return $(this.getTarget()).data('datetimepicker');
    };
    return DatePickerControlAdapter;
}(BaseControlAdapter));
export default DatePickerControlAdapter;
/**
 * Initializes a {@link DatePickerControlAdapter} for a given node.
 *
 * @param node to initialize
 */
export function createDatePickerControlAdapter(node) {
    var element = $(node);
    var configuration = $(element).data('bootstrapui-datetimepicker');
    var exportFormat;
    if (configuration) {
        exportFormat = configuration.exportFormat;
    }
    return new DatePickerControlAdapter(node, exportFormat);
}
