var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Copyright 2019 the original author or authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @author Steven Gentens
 * @since 2.2.0
 */
import BaseControlAdapter from '../support/base-control-adapter';
import { createControlValueHolder } from '../support/bootstrap-ui-control-value-holder';
function isUnwrapped(target) {
    return $(target).is('input[type="checkbox"]') || $(target).is('input[type="radio"]');
}
function getLabelElement(target) {
    return $("label[for=" + CSS.escape(target.id) + "]");
}
/**
 * {@link BootstrapUiControlAdapter} for checkbox elements. The target of the control adapter is the input control.
 */
var CheckboxControlAdapter = /** @class */ (function (_super) {
    __extends(CheckboxControlAdapter, _super);
    function CheckboxControlAdapter(target) {
        var _this = this;
        var element = isUnwrapped(target) ? target : $(target).find('input[type=checkbox], input[type=radio]')[0];
        _this = _super.call(this, element) || this;
        _this.initialValue = _this.isSelected();
        $(element).change(function (event) { return _this.triggerChange(); });
        return _this;
        // TODO configure 'bootstrapui.submit' event
        // prevent opening the element on enter, but see it as 'submitting' the value instead.
        // $( element ).keypress( this, ( event ) => {
        //     if ( event.key === 'Enter' ) {
        //         this.triggerSubmit();
        //     }
        // } );
    }
    CheckboxControlAdapter.prototype.getValue = function () {
        var target = $(this.getTarget());
        if (this.isSelected()) {
            var labelElement = getLabelElement(this.getTarget());
            return [createControlValueHolder(labelElement.length > 0 ? labelElement.text() : undefined, target.attr('value'), this.getTarget())];
        }
        return [];
    };
    CheckboxControlAdapter.prototype.reset = function () {
        this.selectValue(this.initialValue);
    };
    CheckboxControlAdapter.prototype.selectValue = function (isChecked) {
        $(this.getTarget()).prop('checked', isChecked);
    };
    CheckboxControlAdapter.prototype.isSelected = function () {
        return $(this.getTarget()).is(':checked');
    };
    return CheckboxControlAdapter;
}(BaseControlAdapter));
export default CheckboxControlAdapter;
/**
 * Initializes a {@link CheckboxControlAdapter} for a given node.
 *
 * @param node to initialize
 */
export function createCheckboxControlAdapter(node) {
    return new CheckboxControlAdapter(node);
}
