var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * Copyright 2019 the original author or authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @author Steven Gentens
 * @since 2.2.0
 */
import BaseControlAdapter from '../support/base-control-adapter';
import BootstrapUiAttributes from '../support/bootstrap-ui-attributes';
import { ControlAdapterFactory } from '../support/control-adapter-factory';
/**
 * {@link BootstrapUiControlAdapter} for elements whose state depends on the state of its children.
 * The target of the control adapter is the node on which the control adapter is registered.
 *
 * A container adapters state depends on the state of its nested {@link BootstrapUiControlAdapter}s.
 * When registering event listeners on it is advised to check whether the currentTarget of the event is equal to the target of the control adapter
 * as (some) events bubble up.
 */
var ContainerControlAdapter = /** @class */ (function (_super) {
    __extends(ContainerControlAdapter, _super);
    function ContainerControlAdapter(target) {
        var _this = _super.call(this, target) || this;
        var controlElements = [];
        $(target).find("[data-" + BootstrapUiAttributes.CONTROL_ADAPTER_TYPE + "]")
            .not("[data-" + BootstrapUiAttributes.CONTROL_ADAPTER_TYPE + "=\"container\"]")
            .each(function (index, element) {
            var adapter = $(element).data(BootstrapUiAttributes.CONTROL_ADAPTER);
            controlElements.push(adapter);
            $(adapter.getTarget()).on('bootstrapui.change', function (event) {
                event.stopPropagation();
                _this.triggerChange();
            });
            // TODO configure 'bootstrapui.submit' event
            // $( adapter.getTarget() ).on( 'bootstrapui.submit', ( event ) => {
            //     event.stopPropagation();
            //     this.triggerSubmit();
            // } );
        });
        _this.adapters = controlElements;
        return _this;
    }
    ContainerControlAdapter.prototype.getValue = function () {
        return [].concat([].concat.apply([], this.adapters.map(function (adapter) { return adapter.getValue(); })));
    };
    ContainerControlAdapter.prototype.reset = function () {
        this.adapters.forEach(function (adapter) { return adapter.reset(); });
    };
    ContainerControlAdapter.prototype.selectValue = function (select) {
        throw new Error('Selecting values is currently not support on ContainerControlAdapters.');
    };
    return ContainerControlAdapter;
}(BaseControlAdapter));
export default ContainerControlAdapter;
/**
 * Initializes a {@link ContainerControlAdapter} for a given node.
 * Before initializing the container node, potentially underlying nodes will be initialized as well.
 *
 * @param node to initialize
 */
export function createContainerControlAdapter(node) {
    ControlAdapterFactory.initializeControlAdapters(node);
    return new ContainerControlAdapter(node);
}
