/*
 * Copyright 2019 the original author or authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BaseControlAdapter from '../support/base-control-adapter';
import { createControlValueHolder } from '../support/bootstrap-ui-control-value-holder';
/**
 * {@link BootstrapUiControlAdapter} for select elements.
 * The target of the control adapter is the node on which it is registered.
 */
var SelectControlAdapter = /** @class */ (function (_super) {
    __extends(SelectControlAdapter, _super);
    function SelectControlAdapter(target) {
        var _this = _super.call(this, target) || this;
        _this.initialValue = $(_this.getTarget()).val();
        _this.initializeEventTriggers();
        return _this;
    }
    SelectControlAdapter.prototype.initializeEventTriggers = function () {
        var _this = this;
        $(this.getTarget()).on('change', function (event) { return _this.triggerChange(); });
        // TODO configure 'bootstrapui.submit' event
        // prevent opening the element on enter, but see it as 'submitting' the value instead.
        // $( this.getTarget() ).keypress( this, ( event ) => {
        //     if ( event.key === 'Enter' ) {
        //         event.preventDefault();
        //         this.triggerSubmit();
        //     }
        // } );
    };
    SelectControlAdapter.prototype.getValue = function () {
        var selected = [];
        var selectedOptions = $(this.getTarget()).find('option:checked');
        selectedOptions.each(function () {
            var element = $(this);
            selected.push(createControlValueHolder(element.html(), element.val(), this));
        });
        return selected;
    };
    SelectControlAdapter.prototype.reset = function () {
        this.selectValue(this.initialValue);
    };
    SelectControlAdapter.prototype.selectValue = function (newValue) {
        $(this.getTarget()).val(newValue);
    };
    return SelectControlAdapter;
}(BaseControlAdapter));
export default SelectControlAdapter;
/**
 * Initializes a {@link SelectControlAdapter} for a given node.
 *
 * @param node to initialize
 */
export function createSelectControlAdapter(node) {
    return new SelectControlAdapter(node);
}
